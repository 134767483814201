import Store from 'store'
import { render } from 'utils/jsx'
import ReconnectingWebSocket from 'reconnectingwebsocket'
import App from 'components/App'

///////////////////
////////////////////////////////////////////////////
//////////

let app

;(async () => {
  const websocket = new ReconnectingWebSocket([
    window.location.protocol === 'https:' ? 'wss:' : 'ws:',
    window.location.host
  ].join('//'))
  websocket.onopen = () => websocket.send('')

  // The remote server is the single source of truth
  websocket.onmessage = ({ data }) => {
    if (app) app.destroy()
    app = render(<App items={JSON.parse(data)} />, document.body).components[0]
  }

  Store.items.subscribe(items => {
    if (!items) return
    Store.items.set(undefined)
    websocket.send(JSON.stringify(items))
  })
})()
