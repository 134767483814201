import { Component } from 'utils/jsx'
import classnames from 'classnames'

const ICONS = {
  'icon-account': [
    <path d='M11 7a3 3 0 1 1-6 0 3 3 0 0 1 6 0z' />,
    <path d='M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm4 12.4c-.6-.7-1.4-1.4-3-1.4H7c-1.6 0-2.4.7-3 1.4C2.8 11.3 2 9.8 2 8c0-3.3 2.7-6 6-6s6 2.7 6 6c0 1.8-.8 3.3-2 4.4z' />
  ],
  'icon-add': [
    <path d='M9 4H7v3H4v2h3v3h2V9h3V7H9z' />,
    <path d='M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 14c-3.3 0-6-2.7-6-6s2.7-6 6-6 6 2.7 6 6-2.7 6-6 6z' />
  ],
  'icon-alert': [
    <path d='M7 6h2v4H7V6zM9 12a1 1 0 1 1-2 0 1 1 0 0 1 2 0z' />,
    <path d='M15 16H1a1.001 1.001 0 0 1-.895-1.448l7-14c.34-.678 1.449-.678 1.789 0l7 14A1 1 0 0 1 15 16zM2.618 14h10.764L8 3.236 2.618 14z' />
  ],
  'icon-angle-down': [
    <path d='M8 11.4L2.6 6 4 4.6l4 4 4-4L13.4 6z' />
  ],
  'icon-angle-left': [
    <path d='M10 13.4L4.6 8 10 2.6 11.4 4l-4 4 4 4z' />
  ],
  'icon-angle-right': [
    <path d='M6 13.4L4.6 12l4-4-4-4L6 2.6 11.4 8z' />
  ],
  'icon-angle-up': [
    <path d='M12 11.4l-4-4-4 4L2.6 10 8 4.6l5.4 5.4z' />
  ],
  'icon-attachment': [
    <path d='M5,5v4c0,1.7,1.3,3,3,3s3-1.3,3-3V4.5C11,2,9,0,6.5,0S2,2,2,4.5V10c0,3.3,2.7,6,6,6s6-2.7,6-6V4h-2v6 c0,2.2-1.8,4-4,4s-4-1.8-4-4V4.5C4,3.1,5.1,2,6.5,2S9,3.1,9,4.5V9c0,0.6-0.4,1-1,1S7,9.6,7,9V5H5z' />
  ],
  'icon-bars': [
    <path d='M15 7H1c-.6 0-1 .4-1 1s.4 1 1 1h14c.6 0 1-.4 1-1s-.4-1-1-1zM15 1H1c-.6 0-1 .4-1 1s.4 1 1 1h14c.6 0 1-.4 1-1s-.4-1-1-1zM15 13H1c-.6 0-1 .4-1 1s.4 1 1 1h14c.6 0 1-.4 1-1s-.4-1-1-1z' />
  ],
  'icon-bell': [
    <path d='M10,14H6c0,1.1,0.9,2,2,2S10,15.1,10,14z' />,
    <path d='M15,11h-0.5C13.8,10.3,13,9.3,13,8V5c0-2.8-2.2-5-5-5S3,2.2,3,5v3c0,1.3-0.8,2.3-1.5,3H1c-0.6,0-1,0.4-1,1 s0.4,1,1,1h14c0.6,0,1-0.4,1-1S15.6,11,15,11z' />
  ],
  'icon-blank': [],
  'icon-bold': [
    <path d='M11.561 7.316a3.485 3.485 0 0 0 1.418-3.208C12.781 2.305 11.144.999 9.33.999H2.001v1l1.447.724a1 1 0 0 1 .553.894v8.764a.998.998 0 0 1-.553.894l-1.447.724v1h7.823c2.104 0 3.98-1.547 4.162-3.643a4 4 0 0 0-2.424-4.04zM7 3h1a2 2 0 1 1 0 4H7V3zm2 10H7V9h2a2 2 0 1 1 0 4z' />
  ],
  'icon-bolt': [
    <path d='M9.2 0H5.4c-.4 0-.8.3-1 .7l-2 7c-.2.7.3 1.3.9 1.3H7l-1.5 7 7.3-9.4c.5-.6 0-1.6-.8-1.6H9l1.1-3.7C10.3.6 9.8 0 9.2 0z' />
  ],
  'icon-book': [
    <path d='M7.6,15.9l-7-3C0.2,12.8,0,12.4,0,12V1c0-0.7,0.7-1.2,1.4-0.9L8,2.9l6.6-2.8C15.3-0.2,16,0.3,16,1v11 c0,0.4-0.2,0.8-0.6,0.9l-7,3C8.1,16,7.9,16,7.6,15.9z M2,11.3l6,2.6l6-2.6V2.5L8.4,4.9C8.1,5,7.9,5,7.6,4.9L2,2.5V11.3z' />,
    <rect x='2.8' y='6.2' transform='matrix(0.9191 0.3939 -0.3939 0.9191 3.0495 -1.4271)' width='4.4' height='1' />,
    <rect x='2.8' y='9.4' transform='matrix(0.9191 0.3939 -0.3939 0.9191 4.2872 -1.173)' width='4.4' height='1' />,
    <rect x='10.5' y='4.5' transform='matrix(0.3937 0.9192 -0.9192 0.3937 12.8415 -6.0395)' width='1' height='4.4' />,
    <rect x='10.5' y='7.7' transform='matrix(0.3937 0.9192 -0.9192 0.3937 15.7298 -4.1344)' width='1' height='4.4' />
  ],
  'icon-bookmark': [
    <path d='M4,0C2.9,0,2,0.9,2,2v14l6-3l6,3V2c0-1.1-0.9-2-2-2H4z' />
  ],
  'icon-box': [
    <path d='M15.4,3.1l-7-3C8.1,0,7.9,0,7.6,0.1l-7,3C0.2,3.2,0,3.6,0,4v8c0,0.4,0.2,0.8,0.6,0.9l7,3C7.7,16,7.9,16,8,16 s0.3,0,0.4-0.1l7-3c0.4-0.2,0.6-0.5,0.6-0.9V4C16,3.6,15.8,3.2,15.4,3.1z M8,2.1L12.5,4L8,5.9L3.5,4L8,2.1z M2,5.5l5,2.1v5.8l-5-2.1 V5.5z M9,13.5V7.7l5-2.1v5.8L9,13.5z' />
  ],
  'icon-bug': [
    <path d='M16,4V3h-2v1c0,0.6-0.4,1-1,1h-1c-0.3-0.4-0.6-0.7-1-1V3c0-1.7-1.3-3-3-3S5,1.3,5,3v1C4.6,4.3,4.3,4.6,4,5H3 C2.4,5,2,4.6,2,4V3H0v1c0,1.7,1.3,3,3,3h0.1C3,7.3,3,7.7,3,8H0v2h3v1c-1.7,0-3,1.3-3,3v1h2v-1c0-0.6,0.4-1,1-1h0.4 C4.2,14.8,6,16,8,16s3.8-1.2,4.6-3H13c0.6,0,1,0.4,1,1v1h2v-1c0-1.7-1.3-3-3-3v-1h3V8h-3c0-0.3,0-0.7-0.1-1H13C14.7,7,16,5.7,16,4z' />
  ],
  'icon-calendar': [
    <path d='M15 2h-2V0h-2v2H9V0H7v2H5V0H3v2H1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1zm-1 12H2V5h12v9z' />,
    <path d='M4 7h2v2H4V7zM7 7h2v2H7V7zM4 10h2v2H4v-2zM7 10h2v2H7v-2zM10 7h2v2h-2V7zM10 10h2v2h-2v-2z' />
  ],
  'icon-cancel': [
    <path d='M10.1 4.5L8 6.6 5.9 4.5 4.5 5.9 6.6 8l-2.1 2.1 1.4 1.4L8 9.4l2.1 2.1 1.4-1.4L9.4 8l2.1-2.1z' />,
    <path d='M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 14c-3.3 0-6-2.7-6-6s2.7-6 6-6 6 2.7 6 6-2.7 6-6 6z' />
  ],
  'icon-cancel-small': [
    <polygon points='10.1,4.5 8,6.6 5.9,4.5 4.5,5.9 6.6,8 4.5,10.1 5.9,11.5 8,9.4 10.1,11.5 11.5,10.1 9.4,8 11.5,5.9 ' />
  ],
  'icon-car': [
    <path d='M15.9,8.6L15,6.7l-1-5.9C13.9,0.4,13.5,0,13,0H3C2.5,0,2.1,0.4,2,0.8L1,6.7L0.1,8.6C0,8.7,0,8.8,0,9v4 c0,0.6,0.4,1,1,1v1c0,0.6,0.4,1,1,1h2c0.6,0,1-0.4,1-1v-1h6v1c0,0.6,0.4,1,1,1h2c0.6,0,1-0.4,1-1v-1c0.6,0,1-0.4,1-1V9 C16,8.8,16,8.7,15.9,8.6z M3.8,2h8.3l0.7,4H3.2L3.8,2z M2,12V9.2L2.6,8h10.8L14,9.2V12H2z' />,
    <circle cx='4' cy='10' r='1' />,
    <circle cx='12' cy='10' r='1' />
  ],
  'icon-cart': [
    <path d='M15,3H4.5L4,0.8C3.9,0.3,3.5,0,3,0H0v2h2.2L4,10.2C4.1,10.7,4.5,11,5,11h8c0.4,0,0.8-0.3,0.9-0.7l2-6 C16.1,3.8,15.8,3,15,3z' />,
    <circle cx='5' cy='14' r='2' />,
    <circle cx='13' cy='14' r='2' />
  ],
  'icon-chart': [
    <path d='M1,15h13c0.552,0,1-0.448,1-1v0c0-0.552-0.448-1-1-1H1c-0.552,0-1,0.448-1,1v0 C0,14.552,0.448,15,1,15z' />,
    <rect x='6' y='1' width='3' height='10' />,
    <rect x='1' y='5' width='3' height='6' />,
    <rect x='11' y='7' width='3' height='4' />
  ],
  'icon-chat': [
    <path d='M15,4h-1v6c0,0.552-0.448,1-1,1H6.828L5,13h5l3,3v-3h2c0.552,0,1-0.448,1-1V5 C16,4.448,15.552,4,15,4z' />,
    <path d='M1,0h10c0.552,0,1,0.448,1,1v7c0,0.552-0.448,1-1,1H6l-3,3V9H1C0.448,9,0,8.552,0,8V1C0,0.448,0.448,0,1,0z' />
  ],
  'icon-check': [
    <path d='M8 0C3.589 0 0 3.589 0 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8zm0 14c-3.309 0-6-2.691-6-6s2.691-6 6-6 6 2.691 6 6-2.691 6-6 6z' />,
    <path d='M7 11.414L3.586 8 5 6.586l2 2 4-4L12.414 6z' />
  ],
  'icon-circle': [
    <circle cx='8' cy='8' r='8' />
  ],
  'icon-circle-filled': [
    <path d='M8,0C3.6,0,0,3.6,0,8s3.6,8,8,8s8-3.6,8-8S12.4,0,8,0z M8,14c-3.3,0-6-2.7-6-6s2.7-6,6-6s6,2.7,6,6 S11.3,14,8,14z' />,
    <circle cx='8' cy='8' r='4' />
  ],
  'icon-circle-half': [
    <path d='M8,0C3.6,0,0,3.6,0,8s3.6,8,8,8s8-3.6,8-8S12.4,0,8,0z M2,8c0-3.3,2.7-6,6-6v12C4.7,14,2,11.3,2,8z' />
  ],
  'icon-circle-nested': [
    <path d='M8,0C3.6,0,0,3.6,0,8s3.6,8,8,8s8-3.6,8-8S12.4,0,8,0z M8,14c-3.3,0-6-2.7-6-6s2.7-6,6-6s6,2.7,6,6 S11.3,14,8,14z' />,
    <path d='M8,4C5.8,4,4,5.8,4,8s1.8,4,4,4s4-1.8,4-4S10.2,4,8,4z M8,10c-1.1,0-2-0.9-2-2s0.9-2,2-2 s2,0.9,2,2S9.1,10,8,10z' />
  ],
  'icon-circle-outline': [
    <path d='M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 14c-3.3 0-6-2.7-6-6s2.7-6 6-6 6 2.7 6 6-2.7 6-6 6z' />
  ],
  'icon-clock': [
    <path d='M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 14c-3.3 0-6-2.7-6-6s2.7-6 6-6 6 2.7 6 6-2.7 6-6 6z' />,
    <path d='M9 4H7v5h5V7H9z' />
  ],
  'icon-code': [
    <path d='M12.7 11.7l-1.4-1.4L13.6 8l-2.3-2.3 1.4-1.4 3 3c.4.4.4 1 0 1.4l-3 3zM3.3 11.7l-3-3c-.4-.4-.4-1 0-1.4l3-3 1.4 1.4L2.4 8l2.3 2.3-1.4 1.4zM6 15c-.1 0-.2 0-.3-.1-.5-.2-.8-.7-.6-1.3l4-12c.2-.5.7-.8 1.3-.6.5.2.8.7.6 1.3l-4 12c-.2.4-.6.7-1 .7z' />
  ],
  'icon-cog': [
    <path d='M13.3 5.2l1.1-2.1L13 1.7l-2.1 1.1c-.3-.2-.7-.3-1.1-.4L9 0H7l-.8 2.3c-.3.1-.7.2-1 .4L3.1 1.6 1.6 3.1l1.1 2.1c-.2.3-.3.7-.4 1L0 7v2l2.3.8c.1.4.3.7.4 1.1L1.6 13 3 14.4l2.1-1.1c.3.2.7.3 1.1.4L7 16h2l.8-2.3c.4-.1.7-.3 1.1-.4l2.1 1.1 1.4-1.4-1.1-2.1c.2-.3.3-.7.4-1.1L16 9V7l-2.3-.8c-.1-.3-.2-.7-.4-1zM8 11c-1.7 0-3-1.3-3-3s1.3-3 3-3 3 1.3 3 3-1.3 3-3 3z' />
  ],
  'icon-collapse': [
    <path d='M15.707 1.707A.999.999 0 1 0 14.293.293l-2.5 2.5L9 0v7h7l-2.793-2.793 2.5-2.5zM2.793 11.793l-2.5 2.5a.999.999 0 1 0 1.414 1.414l2.5-2.5L7 16V9H0l2.793 2.793z' />
  ],
  'icon-copy': [
    <path d='M10 4H2c-.6 0-1 .4-1 1v10c0 .6.4 1 1 1h8c.6 0 1-.4 1-1V5c0-.6-.4-1-1-1z' />,
    <path d='M14 0H4v2h9v11h2V1c0-.6-.4-1-1-1z' />
  ],
  'icon-credit-card': [
    <path d='M16,5V3c0-0.6-0.4-1-1-1H1C0.4,2,0,2.4,0,3v2H16z' />,
    <path d='M0,7v6c0,0.6,0.4,1,1,1h14c0.6,0,1-0.4,1-1V7H0z M6,11H2v-1h4V11z M14,11h-2v-1h2V11z' />
  ],
  'icon-dashboard': [
    <path d='M6 9H1a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1zM6 16H1a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1zM15 6h-5a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1zM15 16h-5a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1z' />
  ],
  'icon-document': [
    <path d='M14 0H2c-.6 0-1 .4-1 1v14c0 .6.4 1 1 1h12c.6 0 1-.4 1-1V1c0-.6-.4-1-1-1zm-1 14H3V2h10v12z' />,
    <path d='M4 3h4v4H4V3zM9 4h3v1H9V4zM9 6h3v1H9V6zM4 8h8v1H4V8zM4 10h8v1H4v-1zM4 12h5v1H4v-1z' />
  ],
  'icon-dots': [
    <path d='M10 8a2 2 0 1 1-3.999.001A2 2 0 0 1 10 8zM4 8a2 2 0 1 1-3.999.001A2 2 0 0 1 4 8zM16 8a2 2 0 1 1-3.999.001A2 2 0 0 1 16 8z' />
  ],
  'icon-download': [
    <path d='M8 12c.3 0 .5-.1.7-.3L14.4 6 13 4.6l-4 4V0H7v8.6l-4-4L1.6 6l5.7 5.7c.2.2.4.3.7.3z' />,
    <path d='M14 14H2v-3H0v4c0 .6.4 1 1 1h14c.6 0 1-.4 1-1v-4h-2v3z' />
  ],
  'icon-draft': [
    <path d='M4 0v9H0v4c0 1.7 1.3 3 3 3h10c1.7 0 3-1.3 3-3V0H4zm10 13c0 .6-.4 1-1 1H6V2h8v11z' />,
    <path d='M8 5h4v2H8V5zM8 9h4v2H8V9z' />
  ],
  'icon-edit': [
    <path d='M8.1 3.5L.3 11.3c-.2.2-.3.4-.3.7v3c0 .6.4 1 1 1h3c.3 0 .5-.1.7-.3l7.8-7.8-4.4-4.4zM15.7 3.3l-3-3c-.4-.4-1-.4-1.4 0L9.5 2.1l4.4 4.4 1.8-1.8c.4-.4.4-1 0-1.4z' />
  ],
  'icon-email': [
    <path d='M15 1H1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zm-1 12H2V6.723l5.504 3.145a.998.998 0 0 0 .992 0L14 6.723V13zm0-8.58L8 7.849 2 4.42V3h12v1.42z' />
  ],
  'icon-expand': [
    <path d='M7.4 10L6 8.6l-2.7 2.7L0 8v8h8l-3.3-3.3zM11.3 3.3L8.6 6 10 7.4l2.7-2.7L16 8V0H8z' />
  ],
  'icon-facebook': [
    <path d='M15.3 0H.7C.3 0 0 .3 0 .7v14.7c0 .3.3.6.7.6H8v-5H6V8h2V6c0-2.1 1.2-3 3-3h2v3h-1c-.6 0-1 .4-1 1v1h2.6l-.6 3h-2v5h4.3c.4 0 .7-.3.7-.7V.7c0-.4-.3-.7-.7-.7z' />
  ],
  'icon-file-word': [
    <g>
      <circle cx='14' cy='17' r='1' />
      <path d='M28,32H0V0h28V32z M2,30h24V2H2V30z' />
      <path d='M17.6,25h-7.2l-4.6-8.1L14,6.4l8.2,10.5L17.6,25z M11.6,23h4.8l3.4-5.9L14,9.6l-5.8,7.5L11.6,23z' />
      <rect x='7' y='23' width='14' height='2' />
      <circle cx='14' cy='17' r='2' />
    </g>
  ],
  'icon-file-audio': [
    <path d='M28,32H0V0h28V32z M2,30h24V2H2V30z' />,
    <polygon points='12,20.5 10,20.5 10,8 21,8 21,18.5 19,18.5 19,10 12,10' />,
    <path d='M8.5,24C6.6,24,5,22.4,5,20.5S6.6,17,8.5,17s3.5,1.6,3.5,3.5S10.4,24,8.5,24z M8.5,19C7.7,19,7,19.7,7,20.5 S7.7,22,8.5,22s1.5-0.7,1.5-1.5S9.3,19,8.5,19z' />,
    <path d='M17.5,22c-1.9,0-3.5-1.6-3.5-3.5s1.6-3.5,3.5-3.5s3.5,1.6,3.5,3.5S19.4,22,17.5,22z M17.5,17 c-0.8,0-1.5,0.7-1.5,1.5s0.7,1.5,1.5,1.5s1.5-0.7,1.5-1.5S18.3,17,17.5,17z' />
  ],
  'icon-file-image': [
    <polygon points='13.1,23.4 8.1,19.3 5,22.4 3.6,21 7.9,16.7 12.9,20.6 19,13.4 24.4,20.2 22.8,21.4 19,16.6  ' />,
    <path d='M28,32H0V0h28V32z M2,30h24V2H2V30z' />,
    <path d='M12.5,14C10.6,14,9,12.4,9,10.5S10.6,7,12.5,7S16,8.6,16,10.5S14.4,14,12.5,14z M12.5,9 C11.7,9,11,9.7,11,10.5s0.7,1.5,1.5,1.5s1.5-0.7,1.5-1.5S13.3,9,12.5,9z' />
  ],
  'icon-file-text': [
    <path d='M32,32H0V0h32V32z M2,30h28V2H2V30z' />,
    <polygon points='23.1,25.4 16.3,8 15.7,8 8.9,25.4 7.1,24.6 14.3,6 17.7,6 24.9,24.6  ' />,
    <rect x='11.1' y='16' width='9.8' height='2' />,
    <rect x='5' y='24' width='6' height='2' />,
    <rect x='21' y='24' width='6' height='2' />
  ],
  'icon-file-video': [
    <path d='M32,32H0V0h32V32z M2,30h28V2H2V30z' />,
    <path d='M11,18.8V5.2L21.9,12L11,18.8z M13,8.8v6.4l5.1-3.2L13,8.8z' />,
    <rect x='24' y='23' width='4' height='2' />,
    <rect x='4' y='23' width='12' height='2' />,
    <path d='M18,28c-2.2,0-4-1.8-4-4s1.8-4,4-4c2.2,0,4,1.8,4,4S20.2,28,18,28z M18,22c-1.1,0-2,0.9-2,2s0.9,2,2,2 c1.1,0,2-0.9,2-2S19.1,22,18,22z' />
  ],
  'icon-file-code': [
    <path d='M28,32H0V0h28V32z M2,30h24V2H2V30z' />,
    <polygon points='9,20.4 4.6,16 9,11.6 10.4,13 7.4,16 10.4,19' />,
    <polygon points='19,20.4 17.6,19 20.6,16 17.6,13 19,11.6 23.4,16' />,
    <rect x='13' y='8.7' transform='matrix(0.9488 0.3159 -0.3159 0.9488 5.7719 -3.6035)' width='2' height='14.6' />
  ],
  'icon-file-zip': [
    <polygon points='28,32 0,32 0,0 15,0 15,8 13,8 13,2 2,2 2,30 26,30 26,2 18,2 18,0 28,0  ' />,
    <path d='M18,19h-8v-9h8V19z M12,17h4v-5h-4V17z' />,
    <rect x='12' y='25' width='4' height='2' />,
    <rect x='12' y='21' width='4' height='2' />
  ],
  'icon-file-document': [
    <path d='M28,32H0V0h28V32z M2,30h24V2H2V30z' />,
    <rect x='16' y='7' width='6' height='2' />,
    <rect x='16' y='12' width='6' height='2' />,
    <rect x='6' y='17' width='16' height='2' />,
    <rect x='6' y='22' width='16' height='2' />,
    <path d='M14,14H6V7h8V14z M8,12h4V9H8V12z' />
  ],
  'icon-file-spreadsheet': [
    <path d='M28,32H0V0h28V32z M2,30h24V2H2V30z' />,
    <rect x='13' y='10' width='2' height='12' />,
    <rect x='8' y='17' width='2' height='5' />,
    <rect x='18' y='14' width='2' height='8' />
  ],
  'icon-file': [
    <path d='M28,32H0V0h28V32z M2,30h24V2H2V30z' />,
    <path d='M19.5,22h-11C6.6,22,5,20.4,5,18.5c0-1.8,1.3-3.3,3.1-3.5c0.5-2.8,2.9-5,5.9-5s5.5,2.2,5.9,5 c1.7,0.2,3.1,1.7,3.1,3.5C23,20.4,21.4,22,19.5,22z M8.5,17C7.7,17,7,17.7,7,18.5S7.7,20,8.5,20h11c0.8,0,1.5-0.7,1.5-1.5 S20.3,17,19.5,17c-0.1,0-0.2,0-0.3,0L18,17.3l0-1.3c0-2.2-1.8-3.9-4-3.9c-2.2,0-4,1.8-4,3.9l0,0.1v1.2L8.8,17C8.7,17,8.6,17,8.5,17 z' />
  ],
  'icon-folder': [
    <path d='M15,3H8V1c0-0.6-0.4-1-1-1H1C0.4,0,0,0.4,0,1v14c0,0.6,0.4,1,1,1h14c0.6,0,1-0.4,1-1V4C16,3.4,15.6,3,15,3z M6,2v2c0,0.6,0.4,1,1,1h7v2H2V2H6z M2,14V9h12v5H2z' />
  ],
  'icon-funnel': [
    <path d='M9 15H7a1 1 0 0 1 0-2h2a1 1 0 0 1 0 2zM11 11H5a1 1 0 0 1 0-2h6a1 1 0 0 1 0 2zM13 7H3a1 1 0 0 1 0-2h10a1 1 0 0 1 0 2zM15 3H1a1 1 0 0 1 0-2h14a1 1 0 0 1 0 2z' />
  ],
  'icon-globe': [
    <path d='M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm5.9 7H12c-.1-1.5-.4-2.9-.8-4.1 1.4.9 2.4 2.4 2.7 4.1zM8 14c-.6 0-1.8-1.9-2-5h4c-.2 3.1-1.4 5-2 5zM6 7c.2-3.1 1.3-5 2-5s1.8 1.9 2 5H6zM4.9 2.9C4.4 4.1 4.1 5.5 4 7H2.1c.3-1.7 1.3-3.2 2.8-4.1zM2.1 9H4c.1 1.5.4 2.9.8 4.1-1.4-.9-2.4-2.4-2.7-4.1zm9 4.1c.5-1.2.7-2.6.8-4.1h1.9c-.2 1.7-1.2 3.2-2.7 4.1z' />
  ],
  'icon-headline': [
    <path d='M0 0v6h1l2-3h3v10l-3 2v1h10v-1l-3-2V3h3l2 3h1V0z' />
  ],
  'icon-heart': [
    <path d='M11.6,0C10.1,0,8.8,0.8,8,2C7.2,0.8,5.9,0,4.4,0C2,0,0,2,0,4.4c0,4.4,8,10.9,8,10.9s8-6.5,8-10.9 C16,2,14,0,11.6,0z' />
  ],
  'icon-hidden': [
    <path d='M14.574,5.669l-1.424,1.424C13.428,7.44,13.656,7.757,13.819,8c-0.76,1.13-2.85,3.82-5.561,3.985L6.443,13.8 C6.939,13.924,7.457,14,8,14c4.707,0,7.744-5.284,7.871-5.508c0.171-0.304,0.172-0.676,0.001-0.98 C15.825,7.427,15.372,6.631,14.574,5.669z' />,
    <path d='M0.293,15.707C0.488,15.902,0.744,16,1,16s0.512-0.098,0.707-0.293l14-14c0.391-0.391,0.391-1.023,0-1.414 s-1.023-0.391-1.414,0l-2.745,2.745C10.515,2.431,9.331,2,8,2C3.245,2,0.251,7.289,0.126,7.514 c-0.169,0.303-0.168,0.672,0.002,0.975c0.07,0.125,1.044,1.802,2.693,3.276l-2.529,2.529C-0.098,14.684-0.098,15.316,0.293,15.707z M2.181,7.999C2.958,6.835,5.146,4,8,4c0.742,0,1.437,0.201,2.078,0.508L8.512,6.074C8.348,6.029,8.178,6,8,6C6.895,6,6,6.895,6,8 c0,0.178,0.029,0.348,0.074,0.512L4.24,10.346C3.285,9.51,2.559,8.562,2.181,7.999z' />
  ],
  'icon-home': [
    <rect x='6' y='6' width='4' height='2' />,
    <path d='M15.651,6.241l-7-6c-0.375-0.322-0.927-0.322-1.302,0L4,3.112V2c0-0.553-0.448-1-1-1S2,1.447,2,2v2.826 L0.349,6.241C-0.07,6.601-0.119,7.231,0.241,7.65c0.359,0.42,0.99,0.468,1.41,0.108L2,7.46V15c0,0.553,0.448,1,1,1h10 c0.552,0,1-0.447,1-1V7.46l0.349,0.299C14.538,7.921,14.769,8,15,8c0.282,0,0.562-0.118,0.76-0.35 C16.119,7.231,16.07,6.601,15.651,6.241z M12,14h-2v-4H6v4H4V5.746l4-3.428l4,3.428V14z' />
  ],
  'icon-image': [
    <path d='M15 16H1c-.6 0-1-.4-1-1V1c0-.6.4-1 1-1h14c.6 0 1 .4 1 1v14c0 .6-.4 1-1 1zM2 14h12V2H2v12z' />,
    <path d='M6 4c.6 0 1 .4 1 1s-.4 1-1 1-1-.4-1-1 .4-1 1-1zM3 12l2-4 2 2 3-4 3 6z' />
  ],
  'icon-import': [
    <path d='M15 15H1a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h4v2H2v10h12V3h-3V1h4a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1z' />,
    <path d='M9 7V1H7v6H4l4 4 4-4z' />
  ],
  'icon-instagram': [
    <path d='M13.105 3.892a.96.96 0 1 1-1.92 0 .96.96 0 0 1 1.92 0zM8 12c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4zm0-6c-1.103 0-2 .897-2 2s.897 2 2 2 2-.897 2-2-.897-2-2-2z' />,
    <path d='M12 16H4c-2.056 0-4-1.944-4-4V4c0-2.056 1.944-4 4-4h8c2.056 0 4 1.944 4 4v8c0 2.056-1.944 4-4 4zM4 2c-.935 0-2 1.065-2 2v8c0 .953 1.047 2 2 2h8c.935 0 2-1.065 2-2V4c0-.935-1.065-2-2-2H4z' />
  ],
  'icon-italic': [
    <path d='M12 2V0H6v2h1.271a.5.5 0 0 1 .495.571l-1.51 10.571a1 1 0 0 1-.99.859H4v2h6v-2H8.729a.5.5 0 0 1-.495-.571l1.51-10.571a1 1 0 0 1 .99-.859H12z' />
  ],
  'icon-key': [
    <path d='M12.7 0L6.5 6.3C6 6.1 5.5 6 5 6c-2.8 0-5 2.2-5 5s2.2 5 5 5 5-2.2 5-5c0-.5-.1-1.1-.3-1.6L11 8V6h2V4h2l1-1V0h-3.3zM4.5 12c-.8 0-1.5-.7-1.5-1.5S3.7 9 4.5 9 6 9.7 6 10.5 5.3 12 4.5 12z' />
  ],
  'icon-layers': [
    <path d='M8,6H1C0.4,6,0,6.4,0,7v8c0,0.6,0.4,1,1,1h7c0.6,0,1-0.4,1-1V7C9,6.4,8.6,6,8,6z' />,
    <path d='M11,3H3v2h7v8h2V4C12,3.4,11.6,3,11,3z' />,
    <path d='M14,0H6v2h7v8h2V1C15,0.4,14.6,0,14,0z' />
  ],
  'icon-linkedin': [
    <path d='M15.3,0H0.7C0.3,0,0,0.3,0,0.7v14.7C0,15.7,0.3,16,0.7,16h14.7c0.4,0,0.7-0.3,0.7-0.7V0.7 C16,0.3,15.7,0,15.3,0z M4.7,13.6H2.4V6h2.4V13.6z M3.6,5C2.8,5,2.2,4.3,2.2,3.6c0-0.8,0.6-1.4,1.4-1.4c0.8,0,1.4,0.6,1.4,1.4 C4.9,4.3,4.3,5,3.6,5z M13.6,13.6h-2.4V9.9c0-0.9,0-2-1.2-2c-1.2,0-1.4,1-1.4,2v3.8H6.2V6h2.3v1h0c0.3-0.6,1.1-1.2,2.2-1.2 c2.4,0,2.8,1.6,2.8,3.6V13.6z' />
  ],
  'icon-list-bullet': [
    <path d='M4 2a2 2 0 1 1-3.999.001A2 2 0 0 1 4 2zM4 8a2 2 0 1 1-3.999.001A2 2 0 0 1 4 8zM4 14a2 2 0 1 1-3.999.001A2 2 0 0 1 4 14zM6 1h10v2H6V1zM6 7h10v2H6V7zM6 13h10v2H6v-2z' />
  ],
  'icon-list-numbers': [
    <path d='M5 1h11v2H5V1zM5 7h11v2H5V7zM5 13h11v2H5v-2zM.368 4v-.549l.597-.049c.097-.007.111-.035.111-.139V.839c0-.083-.021-.125-.09-.146L.382.568l.076-.569h1.667v3.264c0 .111.007.132.111.139l.569.049V4H.367zM.047 10v-.63l.907-.886c.683-.663.975-.934.975-1.32 0-.251-.122-.433-.48-.433-.373 0-.528.122-.528.602l-.92-.081C.008 6.176.827 6 1.537 6c1.049 0 1.421.447 1.421 1.083s-.44 1.056-.934 1.53l-.765.731h.995c.068 0 .095-.014.108-.081l.095-.534h.717v1.272H.047zM2.344 13.682c.566.087.879.426.879 1.026 0 .879-.659 1.292-1.612 1.292-.673 0-1.272-.239-1.612-.613l.533-.586c.253.246.533.439.999.439.373 0 .693-.133.693-.599 0-.406-.253-.573-.666-.573a2.66 2.66 0 0 0-.493.047v-.666l.327-.04c.479-.06.739-.293.739-.719 0-.253-.113-.46-.486-.46-.359 0-.532.12-.532.593l-.912-.08c0-1.052.793-1.232 1.492-1.232 1.026 0 1.419.393 1.419 1.106 0 .56-.346.912-.766 1.039v.027z' />
  ],
  'icon-live': [
    <circle cx='8' cy='8' r='3' />,
    <path d='M2,8c0-1.6,0.6-3.1,1.8-4.3c0.4-0.4,0.4-1,0-1.4c-0.4-0.4-1-0.4-1.4,0C0.9,3.8,0,5.8,0,8 c0,2.2,0.9,4.2,2.4,5.7C2.6,13.9,2.9,14,3.1,14c0.3,0,0.5-0.1,0.7-0.3c0.4-0.4,0.4-1,0-1.4C2.6,11.1,2,9.6,2,8z' />,
    <path d='M13.6,2.3c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4C13.4,4.9,14,6.4,14,8c0,1.6-0.6,3.1-1.8,4.3 c-0.4,0.4-0.4,1,0,1.4c0.2,0.2,0.5,0.3,0.7,0.3c0.3,0,0.5-0.1,0.7-0.3C15.1,12.2,16,10.2,16,8C16,5.8,15.1,3.8,13.6,2.3z' />
  ],
  'icon-loader': [
    <path d='M8 16a7.928 7.928 0 0 1-3.428-.77l.857-1.807A6.006 6.006 0 0 0 14 8c0-3.309-2.691-6-6-6a6.006 6.006 0 0 0-5.422 8.572l-1.806.859A7.929 7.929 0 0 1 0 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z' />
  ],
  'icon-lock': [
    <path d='M8 0C5.8 0 4 1.8 4 4v1H2c-.6 0-1 .4-1 1v9c0 .6.4 1 1 1h12c.6 0 1-.4 1-1V6c0-.6-.4-1-1-1h-2V4c0-2.2-1.8-4-4-4zm1 11.7V13H7v-1.3c-.6-.3-1-1-1-1.7 0-1.1.9-2 2-2s2 .9 2 2c0 .7-.4 1.4-1 1.7zM10 5H6V4c0-1.1.9-2 2-2s2 .9 2 2v1z' />
  ],
  'icon-logout': [
    <path d='M3.4 2H8v2h2V1c0-.6-.4-1-1-1H1C.4 0 0 .4 0 1v9c0 .3.1.5.3.7l5 5c.2.2.4.3.7.3.1 0 .3 0 .4-.1.4-.1.6-.5.6-.9V6c0-.3-.1-.5-.3-.7L3.4 2zM5 12.6l-3-3V3.4l3 3v6.2z' />,
    <path d='M15.7 7.3L12 3.6 10.6 5l2 2H8v2h4.6l-2 2 1.4 1.4 3.7-3.7c.4-.4.4-1 0-1.4z' />
  ],
  'icon-map': [
    <path d='M14.6,0.1L10,1.9L6.4,0.1C6.2,0,5.9,0,5.6,0.1l-5,2C0.2,2.2,0,2.6,0,3v12c0,0.7,0.7,1.2,1.4,0.9L6,14.1 l3.6,1.8c0.3,0.1,0.5,0.1,0.8,0l5-1.9c0.4-0.1,0.6-0.5,0.6-0.9V1C16,0.3,15.3-0.2,14.6,0.1z M7,2.6l2,1v9.8l-2-1V2.6z M2,3.7l3-1.2 v9.8l-3,1.2V3.7z M14,12.4l-3,1.1V3.7l3-1.2V12.4z' />
  ],
  'icon-markdown': [
    <path d='M14.85 3H1.15C.52 3 0 3.52 0 4.15v7.69C0 12.48.52 13 1.15 13h13.69c.64 0 1.15-.52 1.15-1.15v-7.7C16 3.52 15.48 3 14.85 3zM9 11H7V8L5.5 9.92 4 8v3H2V5h2l1.5 2L7 5h2v6zm2.99.5L9.5 8H11V5h2v3h1.5l-2.51 3.5z' />
  ],
  'icon-menu': [
    <path d='M0 0h16v3H0V0zM0 6h16v3H0V6zM0 12h16v3H0v-3z' />
  ],
  'icon-open': [
    <path d='M8 7l-4 4h3v5h2v-5h3z' />,
    <path d='M0 1v12a1 1 0 0 0 1 1h4v-2H2V6h12v6h-3v2h4a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1H1a1 1 0 0 0-1 1zm2 3V2h12v2H2z' />
  ],
  'icon-page': [
    <path d='M14 0H2c-.6 0-1 .4-1 1v14c0 .6.4 1 1 1h12c.6 0 1-.4 1-1V1c0-.6-.4-1-1-1zm-1 14H3V2h10v12z' />,
    <path d='M4 3h4v4H4V3zM9 4h3v1H9V4zM9 6h3v1H9V6zM4 8h8v1H4V8zM4 10h8v1H4v-1zM4 12h5v1H4v-1z' />
  ],
  'icon-parent': [
    <path d='M14,6H3.4l4-4L6,0.6L0.3,6.3c-0.4,0.4-0.4,1,0,1.4L6,13.4L7.4,12l-4-4H13v7h2V7C15,6.4,14.6,6,14,6z' />
  ],
  'icon-phone': [
    <path d='M15.285 12.305l-2.578-2.594a1 1 0 0 0-1.416-.002L9 12 4 7l2.294-2.294a1 1 0 0 0 .001-1.414L3.715.708a1 1 0 0 0-1.415 0L.004 3.003 0 3c0 7.18 5.82 13 13 13l2.283-2.283a1 1 0 0 0 .002-1.412z' />
  ],
  'icon-pinterest': [
    <path d='M8 0C3.6 0 0 3.6 0 8c0 3.4 2.1 6.3 5.1 7.4-.1-.6-.1-1.6 0-2.3.1-.6.9-4 .9-4s-.2-.4-.2-1.1c0-1.1.7-2 1.5-2 .7 0 1 .5 1 1.1 0 .7-.4 1.7-.7 2.7-.2.8.4 1.4 1.2 1.4 1.4 0 2.5-1.5 2.5-3.7 0-1.9-1.4-3.3-3.3-3.3-2.3 0-3.6 1.7-3.6 3.5 0 .7.3 1.4.6 1.8v.4c-.1.3-.2.8-.2.9s-.1.2-.3.1c-1-.5-1.6-1.9-1.6-3.1C2.9 5.3 4.7 3 8.2 3c2.8 0 4.9 2 4.9 4.6 0 2.8-1.7 5-4.2 5-.8 0-1.6-.4-1.8-.9 0 0-.4 1.5-.5 1.9-.2.7-.7 1.6-1 2.1.8.2 1.6.3 2.4.3 4.4 0 8-3.6 8-8s-3.6-8-8-8z' />
  ],
  'icon-preview': [
    <path d='M8 14c4.707 0 7.744-5.284 7.871-5.508a1 1 0 0 0 .001-.98C15.746 7.287 12.731 2 8 2 3.245 2 .251 7.289.126 7.514a.998.998 0 0 0 .002.975C.254 8.713 3.269 14 8 14zM8 4c2.839 0 5.036 2.835 5.818 4-.784 1.166-2.981 4-5.818 4-2.841 0-5.038-2.838-5.819-4.001C2.958 6.835 5.146 4 8 4z' />,
    <path d='M10 8a2 2 0 1 1-3.999.001A2 2 0 0 1 10 8z' />
  ],
  'icon-protected': [
    <path d='M8,0C3.6,0,0,3.6,0,8s3.6,8,8,8s8-3.6,8-8S12.4,0,8,0z M8,2c1.3,0,2.5,0.4,3.5,1.1l-8.4,8.4 C2.4,10.5,2,9.3,2,8C2,4.7,4.7,2,8,2z M8,14c-1.3,0-2.5-0.4-3.5-1.1l8.4-8.4C13.6,5.5,14,6.7,14,8C14,11.3,11.3,14,8,14z' />
  ],
  'icon-question': [
    <path d='M8,0C3.6,0,0,3.6,0,8s3.6,8,8,8s8-3.6,8-8S12.4,0,8,0z M8,14c-3.3,0-6-2.7-6-6s2.7-6,6-6s6,2.7,6,6 S11.3,14,8,14z' />,
    <circle cx='8' cy='12' r='1' />,
    <path d='M7.1,5.5C7.3,5.2,7.6,5,8,5c0.6,0,1,0.4,1,1c0,0.3-0.1,0.4-0.6,0.7C7.8,7.1,7,7.7,7,9v1h2V9 c0-0.2,0-0.3,0.5-0.6C10.1,8,11,7.4,11,6c0-1.7-1.3-3-3-3C6.9,3,5.9,3.6,5.4,4.5L4.9,5.4l1.7,1L7.1,5.5z' />
  ],
  'icon-quote': [
    <path d='M3.024 4.561C1.839 6.042 1.668 7.535 2.016 8.58c1.316-1.045 3.145-.826 4.258.214 1.125 1.051 1.222 2.898.504 4.094a3.293 3.293 0 0 1-2.847 1.613C1.238 14.501 0 12.126 0 9.462c0-1.73.441-3.28 1.323-4.649S3.536 2.34 5.317 1.5l.479.932c-1.075.454-1.999 1.163-2.772 2.129zm8.768 0c-1.185 1.481-1.356 2.974-1.008 4.019.588-.454 1.226-.68 1.915-.68C14.511 7.9 16 9.107 16 11.201c0 1.926-1.478 3.301-3.301 3.301-2.693 0-3.931-2.375-3.931-5.039 0-1.73.441-3.28 1.323-4.649s2.213-2.473 3.994-3.313l.479.932c-1.075.454-1.999 1.163-2.772 2.129z' />
  ],
  'icon-refresh': [
    <path d='M4.5 4.5c1.9-1.9 5.1-1.9 7 0 .7.7 1.2 1.7 1.4 2.7l2-.3c-.2-1.5-.9-2.8-1.9-3.8C10.3.4 5.9.4 3.1 3.1L.9.9.2 7.3l6.4-.7-2.1-2.1zM15.8 8.7l-6.4.7 2.1 2.1c-1.9 1.9-5.1 1.9-7 0-.7-.7-1.2-1.7-1.4-2.7l-2 .3c.2 1.5.9 2.8 1.9 3.8 1.4 1.4 3.1 2 4.9 2s3.6-.7 4.9-2l2.2 2.2.8-6.4z' />
  ],
  'icon-road-sign': [
    <path d='M9,10v5c0,0.6-0.4,1-1,1H6c-0.6,0-1-0.4-1-1v-5H9z' />,
    <path d='M13,2l3,3l-3,3H1C0.4,8,0,7.6,0,7l0-4c0-0.6,0.4-1,1-1H13z' />,
    <path d='M6,0l2,0c0.6,0,1,0.4,1,1v1H5V1C5,0.4,5.4,0,6,0z' />
  ],
  'icon-remove': [
    <rect x='4' y='7' width='8' height='2' />,
    <path d='M8,0C3.6,0,0,3.6,0,8s3.6,8,8,8s8-3.6,8-8S12.4,0,8,0z M8,14c-3.3,0-6-2.7-6-6s2.7-6,6-6s6,2.7,6,6 S11.3,14,8,14z' />
  ],
  'icon-search': [
    <path d='M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5zM15.707 14.293L13.314 11.9a8.019 8.019 0 0 1-1.414 1.414l2.393 2.393a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414z' />
  ],
  'icon-settings': [
    <path d='M5 5V1c0-.6-.4-1-1-1S3 .4 3 1v4c0 .6.4 1 1 1s1-.4 1-1zM1 11c0 1.3.9 2.4 2 2.8V15c0 .6.4 1 1 1s1-.4 1-1v-1-.2c1.2-.4 2-1.5 2-2.8 0-1.7-1.3-3-3-3s-3 1.3-3 3zM11 11v4c0 .6.4 1 1 1s1-.4 1-1v-4c0-.6-.4-1-1-1s-1 .4-1 1zM9 5c0 1.7 1.3 3 3 3s3-1.3 3-3c0-1.3-.9-2.4-2-2.8V1c0-.6-.4-1-1-1s-1 .4-1 1v1.2C9.9 2.6 9 3.7 9 5z' />
  ],
  'icon-share': [
    <path d='M15,16H1c-0.6,0-1-0.4-1-1V3c0-0.6,0.4-1,1-1h3v2H2v10h12V9h2v6C16,15.6,15.6,16,15,16z' />,
    <path d='M10,3c-3.2,0-6,2.5-6,7c1.1-1.7,2.4-3,6-3v3l6-5l-6-5V3z' />
  ],
  'icon-smile': [
    <path d='M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 14c-3.3 0-6-2.7-6-6s2.7-6 6-6 6 2.7 6 6-2.7 6-6 6z' />,
    <path d='M7 6a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM11 6a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM8 12c1.7 0 3-1.3 3-3H5c0 1.7 1.3 3 3 3z' />
  ],
  'icon-sort': [
    <path d='M0 0h3v3H0V0zM6 0h3v3H6V0zM0 6h3v3H0V6zM6 6h3v3H6V6zM0 12h3v3H0v-3zM6 12h3v3H6v-3z' />
  ],
  'icon-star': [
    <path d='M15.144,5.439l-4.317-.628L8.9.9A1.041,1.041,0,0,0,7.1.9L5.173,4.812.856,5.439A1,1,0,0,0,.3,7.145l3.123,3.045-.737,4.3a1,1,0,0,0,1.451,1.054L8,13.513l3.861,2.029a1,1,0,0,0,1.451-1.054l-.737-4.3L15.7,7.145a1,1,0,0,0-.554-1.705Z' />
  ],
  'icon-strikethrough': [
    <path d='M9,13.27924V11H7v2.27924c0,0.43042-0.27545,0.81256-0.68378,0.94867L4,15v1h4h4v-1l-2.31622-0.77209 C9.27545,14.0918,9,13.70966,9,13.27924z' />,
    <path d='M9,2h3.38196c0.37878,0,0.72504,0.21399,0.89441,0.5528L14,4h1V0H8H1v4h1l0.72363-1.4472 C2.89301,2.21399,3.23926,2,3.61804,2H7v5h2V2z' />,
    <polygon points='16,9 16,7 9,7 7,7 0,7 0,9 ' />
  ],
  'icon-tag': [
    <path d='M15.7 8.3l-8-8C7.5.1 7.3 0 7 0H1C.4 0 0 .4 0 1v6c0 .3.1.5.3.7l8 8c.2.2.4.3.7.3s.5-.1.7-.3l6-6c.4-.4.4-1 0-1.4zM4 5c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1z' />
  ],
  'icon-template': [
    <path d='M15 1h-3v2h2v11H2V3h2V1H1c-.6 0-1 .4-1 1v13c0 .6.4 1 1 1h14c.6 0 1-.4 1-1V2c0-.6-.4-1-1-1z' />,
    <path d='M5 0h6v4H5zM4 6h8v2H4zM4 10h8v2H4z' />
  ],
  'icon-text': [
    <path d='M0 1h16v2H0V1zM0 5h10v2H0V5zM0 9h16v2H0V9zM0 13h10v2H0v-2z' />
  ],
  'icon-title': [
    <path d='M6 1H0v3h1l1-1h3v11l-2 1v1h6v-1l-2-1V3h3l1 1h1V1z' />,
    <path d='M8 6v3h1l1-1h1v6l-1 1v1h4v-1l-1-1V8h1l1 1h1V6z' />
  ],
  'icon-toggle-off': [
    <path d='M8 8a2 2 0 1 1-3.999.001A2 2 0 0 1 8 8z' />,
    <path d='M10 2H6C2.7 2 0 4.7 0 8s2.7 6 6 6h4c3.3 0 6-2.7 6-6s-2.7-6-6-6zm0 10H6c-2.2 0-4-1.8-4-4s1.8-4 4-4h4c2.2 0 4 1.8 4 4s-1.8 4-4 4z' />
  ],
  'icon-toggle-on': [
    <path d='M8,8 C8,6.3 9.3,5 11,5 C12.7,5 14,6.3 14,8 C14,9.7 12.7,11 11,11 C9.3,11 8,9.7 8,8 M0,8 C0,10.8 2.2,13 5,13 L11,13 C13.8,13 16,10.8 16,8 C16,5.2 13.8,3 11,3 L5,3 C2.2,3 0,5.2 0,8' />
  ],
  'icon-trash': [
    <path d='M5 7h2v6H5V7zM9 7h2v6H9V7z' />,
    <path d='M12 1c0-.6-.4-1-1-1H5c-.6 0-1 .4-1 1v2H0v2h1v10c0 .6.4 1 1 1h12c.6 0 1-.4 1-1V5h1V3h-4V1zM6 2h4v1H6V2zm7 3v9H3V5h10z' />
  ],
  'icon-twitter': [
    <path d='M16 3c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4C.7 7.7 1.8 9 3.3 9.3c-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H0c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4C15 4.3 15.6 3.7 16 3z' />
  ],
  'icon-underline': [
    <rect y='14' width='16' height='2' />,
    <path d='M15,0H9v1l1.44721,0.72361C10.786,1.893,11,2.23926,11,2.61803l0,4.26357 c0,1.45087-0.97782,2.7836-2.40207,3.06025C6.68269,10.31386,5,8.84987,5,7V2.61803C5,2.23926,5.214,1.893,5.55279,1.72361L7,1V0H1 v1l1.44721,0.72361C2.786,1.893,3,2.23926,3,2.61803L3,6.777c0,2.60979,1.90274,4.94497,4.50018,5.19855 C10.48047,12.2665,13,9.92173,13,7V2.61803c0-0.37877,0.214-0.72504,0.55279-0.89443L15,1V0z' />
  ],
  'icon-undo': [
    <path d='M2.502 12.333a6.996 6.996 0 0 1-1.405-3.168l1.973-.331a4.982 4.982 0 0 0 1.003 2.26l-1.571 1.239zM6.834 14.903a7.015 7.015 0 0 1-2.976-1.259l1.186-1.611c.624.459 1.358.77 2.122.898l-.332 1.972zM9.165 14.903l-.33-1.973a4.99 4.99 0 0 0 2.209-.964l1.219 1.586a6.997 6.997 0 0 1-3.098 1.351zM8 1c-1.873 0-3.65.759-4.948 2.052L.9.9.2 7.3l6.4-.7-2.135-2.135A5.023 5.023 0 0 1 8 3c2.757 0 5 2.243 5 5 0 1.06-.327 2.072-.947 2.928l1.621 1.173A6.96 6.96 0 0 0 15 8c0-3.86-3.141-7-7-7z' />
  ],
  'icon-unlock': [
    <path d='M1,15c0,0.6,0.4,1,1,1h12c0.6,0,1-0.4,1-1V6c0-0.6-0.4-1-1-1h-2H6V4c0-1.1,0.9-2,2-2c0.6,0,1.1,0.2,1.5,0.7 l0.7,0.7l1.5-1.3L11,1.3C10.2,0.5,9.1,0,8,0C5.8,0,4,1.8,4,4v1H2C1.4,5,1,5.4,1,6V15z M8,8c1.1,0,2,0.9,2,2c0,0.7-0.4,1.4-1,1.7V13 H7v-1.3c-0.6-0.3-1-1-1-1.7C6,8.9,6.9,8,8,8z' />
  ],
  'icon-upload': [
    <path d='M7 3.4V12h2V3.4l4 4L14.4 6 8.7.3c-.4-.4-1-.4-1.4 0L1.6 6 3 7.4l4-4z' />,
    <path d='M14 14H2v-3H0v4c0 .6.4 1 1 1h14c.6 0 1-.4 1-1v-4h-2v3z' />
  ],
  'icon-url': [
    <path d='M11 0C9.7 0 8.4.5 7.5 1.5L6.3 2.6c-.4.4-.4 1 0 1.4s1 .4 1.4 0l1.2-1.2c1.1-1.1 3.1-1.1 4.2 0 .6.6.9 1.4.9 2.2s-.3 1.6-.9 2.1L12 8.3c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l1.2-1.2C15.5 7.6 16 6.3 16 5s-.5-2.6-1.5-3.5C13.6.5 12.3 0 11 0zM8.3 12l-1.2 1.2c-1.1 1.1-3.1 1.1-4.2 0-.6-.6-.9-1.4-.9-2.2s.3-1.6.9-2.1L4 7.7c.4-.4.4-1 0-1.4s-1-.4-1.4 0L1.5 7.5C.5 8.4 0 9.7 0 11s.5 2.6 1.5 3.5c.9 1 2.2 1.5 3.5 1.5s2.6-.5 3.5-1.5l1.2-1.2c.4-.4.4-1 0-1.4s-1-.3-1.4.1z' />,
    <path d='M9.4 5.2L5.2 9.4c-.4.4-.4 1 0 1.4.2.2.5.3.7.3s.5-.1.7-.3l4.2-4.2c.4-.4.4-1 0-1.4s-1-.4-1.4 0z' />
  ],
  'icon-user': [
    <path d='M8 0C5.794 0 4 1.794 4 4v1c0 2.206 1.794 4 4 4s4-1.794 4-4V4c0-2.206-1.794-4-4-4zM12.036 9.426C10.969 10.4 9.555 11 8 11s-2.969-.6-4.036-1.574C2.203 10 1 11.311 1 13v3h14v-3c0-1.689-1.203-3-2.964-3.574z' />
  ],
  'icon-users': [
    <path d='M10.2 11.4l-2.7-2C8.4 8.7 9 7.7 9 6.5v-.8C9 3.8 7.6 2.1 5.7 2 3.7 1.9 2 3.5 2 5.5v1c0 1.2.6 2.2 1.5 2.9L.8 11.5c-.5.4-.8 1-.8 1.6V15c0 .6.4 1 1 1h9c.6 0 1-.4 1-1v-2c0-.6-.3-1.2-.8-1.6zM15.1 6.4l-1.8-1.2c.4-.4.7-1 .7-1.7v-.9c0-1.2-.9-2.4-2.1-2.6S9.7.5 9.2 1.4c1.1 1 1.8 2.4 1.8 4v1c0 .9-.2 1.8-.6 2.5 0 0 1.2.9 1.2 1H15c.6 0 1-.4 1-1v-.8c0-.7-.3-1.3-.9-1.7z' />
  ],
  'icon-video': [
    <path d='M11 9V7c0-.6-.4-1-1-1H1c-.6 0-1 .4-1 1v8c0 .6.4 1 1 1h9c.6 0 1-.4 1-1v-2l5 2V7l-5 2zM5 2.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0zM10 3a2 2 0 1 1-3.999.001A2 2 0 0 1 10 3z' />
  ],
  'icon-vimeo': [
    <path d='M16,4.3c-0.1,1.6-1.2,3.7-3.3,6.4c-2.2,2.8-4,4.2-5.5,4.2c-0.9,0-1.7-0.9-2.4-2.6C4,9.9,3.4,5,2,5 C1.9,5,1.5,5.3,0.8,5.8L0,4.8c0.8-0.7,3.5-3.4,4.7-3.5C5.9,1.2,6.7,2,7,3.8c0.3,2,0.8,6.1,1.8,6.1c0.9,0,2.5-3.4,2.6-4 c0.1-0.9-0.3-1.9-2.3-1.1c0.8-2.6,2.3-3.8,4.5-3.8C15.3,1.1,16.1,2.2,16,4.3z' />
  ],
  'icon-wand': [
    <path d='M8.046 4.985l2.97 2.97-7.99 7.99-2.97-2.97 7.99-7.99zM12.995 1.591l1.414 1.414-2.404 2.404-1.414-1.414 2.404-2.404zM13 7h3v2h-3V7zM12.243 10.834l2.121 2.121-1.414 1.414-2.121-2.121 1.414-1.414zM7 0h2v3H7V0zM3.05 1.631l2.121 2.121-1.414 1.414-2.121-2.121L3.05 1.631z' />
  ],
  'icon-whatsapp': [
    <path d='M13.6,2.3C12.1,0.8,10.2,0,8,0C3.7,0,0.1,3.6,0.1,7.9c0,1.4,0.4,2.8,1.1,4L0,16l4.2-1.1c1.2,0.6,2.5,1,3.8,1 h0h0c4.4,0,7.9-3.6,7.9-7.9C16,5.8,15.1,3.8,13.6,2.3z M11.9,10.8c-0.2,0.5-1,0.9-1.3,0.9c-0.3,0.1-1.3,0-2.4-0.5 c-2-0.9-3.3-2.9-3.4-3C4.7,8.1,4,7.1,4,6.2s0.5-1.5,0.7-1.7c0.4-0.4,1.2-0.3,1.4,0.1C6.2,5,6.6,6,6.7,6.1s0.1,0.5-0.2,0.7 C6.4,6.8,5.9,7.2,6.1,7.5c0.1,0.2,0.5,0.8,1.1,1.4c0.8,0.7,1.4,0.9,1.6,1C9,10,9.1,10,9.2,9.8C9.3,9.7,9.7,9.2,9.8,9 c0.1-0.2,0.3-0.2,0.4-0.1s1.2,0.5,1.4,0.6C11.8,9.7,12,9.7,12,9.8C12.1,9.9,12.1,10.3,11.9,10.8z' />
  ],
  'icon-wheelchair': [
    <circle cx='11' cy='15' r='1' />,
    <circle cx='11' cy='2' r='2' />,
    <path d='M14.949,5.684a1,1,0,0,0-1.265-.632l-2.414.8L9.707,4.293A1,1,0,0,0,9,4H6a1,1,0,0,0-.447.105l-2,1A1,1,0,1,0,4.447,6.9L6.236,6H7.523L6.071,9.629A1,1,0,0,0,7,11h3v2h2V10a1,1,0,0,0-1-1H8.477l.888-2.221.928.928A1,1,0,0,0,11,8a.987.987,0,0,0,.316-.052l3-1A1,1,0,0,0,14.949,5.684Z' />,
    <path d='M5,16A4,4,0,0,1,5,8v2a2,2,0,1,0,2,2H9A4,4,0,0,1,5,16Z' />
  ],
  'icon-youtube': [
    <path d='M15.8,4.8c-0.2-1.3-0.8-2.2-2.2-2.4C11.4,2,8,2,8,2S4.6,2,2.4,2.4C1,2.6,0.3,3.5,0.2,4.8C0,6.1,0,8,0,8 s0,1.9,0.2,3.2c0.2,1.3,0.8,2.2,2.2,2.4C4.6,14,8,14,8,14s3.4,0,5.6-0.4c1.4-0.3,2-1.1,2.2-2.4C16,9.9,16,8,16,8S16,6.1,15.8,4.8z M6,11V5l5,3L6,11z' />
  ]
}

export default class Icon extends Component {
  template (props) {
    return (
      <svg viewBox='0 0 16 16' class={classnames('icon', props.class)}>
        {ICONS[`icon-${props.name}`]}
      </svg>
    )
  }
}
