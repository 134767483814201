import { Component } from 'utils/jsx'
import classnames from 'classnames'
import Button from 'components/Button'
import Icon from 'components/Icon'

const capitalize = str => str.charAt(0).toUpperCase() + str.slice(1)

export default class Item extends Component {
  beforeRender (props) {
    this.handleToggle = this.handleToggle.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.state = {
      active: props.active
    }
  }

  template (props) {
    return (
      <li
        class={classnames('item', { 'is-active': this.state.active })}
        event-click={this.handleToggle}
      >
        <Icon name='bars' class='item__handle' />
        <div class='item__name'>{props.name}</div>
        <Button icon='trash' event-click={this.handleDelete} />
      </li>
    )
  }

  get DOMIndex () {
    return [...this.base.parentNode.children].indexOf(this.base)
  }

  toJson () {
    return {
      name: capitalize(this.props.name),
      active: this.state.active
    }
  }

  handleToggle () {
    this.state.active = !this.state.active
    this.props['event-toggle']()
  }

  handleDelete () {
    const callback = this.props['event-delete']
    this.destroy()
    callback()
  }
}
