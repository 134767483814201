import Store from 'store'
import { Component } from 'utils/jsx'

import Sortable from 'sortablejs'
import Button from 'components/Button'
import Item from 'components/Item'

export default class App extends Component {
  beforeRender (props) {
    this.handleToggle = this.handleToggle.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.handleSort = this.handleSort.bind(this)
    this.handlePrompt = this.handlePrompt.bind(this)
  }

  template (props) {
    return (
      <main
        class='app'
        id='App'
      >
        <ul class='app__items' ref={this.ref('itemsContainer')}>
          {
            props.items.map(item => (
              <Item
                {...item}
                ref={this.refArray('items')}
                event-toggle={this.handleToggle}
                event-delete={this.handleDelete}
              />
            ))
          }
        </ul>

        <Button icon='cart' class='add' event-click={this.handlePrompt} />
      </main>
    )
  }

  push (item, { top = false } = {}) {
    const items = (this.refs.items || [])
      .filter(Boolean)
      .sort((a, b) => a.DOMIndex - b.DOMIndex)
      .map(item => item.toJson())
      .sort((a, b) => {
        if (a.active && !b.active) return -1
        if (!a.active && b.active) return 1
        return 0
      })

    for (const i in items) {
      if (!item) break
      if (items[i].name.toUpperCase() === item.name.toUpperCase()) {
        delete items[i]
      }
    }

    Store.items.set((top ? [item, ...items] : [...items, item]).filter(Boolean) || [])
  }

  afterMount () {
    this.sortable = Sortable.create(this.refs.itemsContainer, {
      handle: '.item__handle',
      onEnd: this.handleSort
    })
  }

  handleToggle () {
    this.push()
  }

  handleDelete () {
    this.push()
  }

  handleSort () {
    this.push()
  }

  handlePrompt () {
    const name = window.prompt('Ajouter un item')
    if (!name) return
    this.push({ name, active: true }, { top: true })
    window.scrollTo(0, 0)
  }

  beforeDestroy () {
    this.sortable.destroy()
  }
}
